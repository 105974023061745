<template>
  <div class="app-container">
    <div class="list">
      <el-row class="mb20">
        <el-col
          v-if="buttonShow"
          :sm="searchControl.firstSoltSpan[0]"
          :md="searchControl.firstSoltSpan[1]"
          :lg="searchControl.firstSoltSpan[2]"
          :xl="searchControl.firstSoltSpan[3]"
        >
          <div class="flex" style="max-height: 40px">
            <el-button
              type="success"
              icon="el-icon-plus"
              @click="showAdd"
              v-if="btnFlag.addShow"
              >{{ addBtnText }}</el-button
            >

            <el-button
              v-if="btnFlag.editShow"
              type="primary"
              :disabled="selecTionArr.length !== 1"
              icon="el-icon-edit"
              @click="showEdit(selecTionArr[0])"
              >{{ editBtnText }}</el-button
            >
            <el-button
              v-if="selecTionArr.length > 0"
              type="danger"
              icon="el-icon-delete"
              @click="handelDel"
              >{{ deleteBtnText }}</el-button
            >
          </div>
        </el-col>
        <el-col
          v-if="searchControl.secondSoltSpan[0] !== 0"
          :sm="searchControl.secondSoltSpan[0]"
          :md="searchControl.secondSoltSpan[1]"
          :lg="searchControl.secondSoltSpan[2]"
          :xl="searchControl.secondSoltSpan[3]"
        >
          <slot name="toolSub" :selecTionArr="selecTionArr"></slot>
        </el-col>
        <el-col
          :sm="searchControl.thirdSoltSpan[0]"
          :md="searchControl.thirdSoltSpan[1]"
          :lg="searchControl.thirdSoltSpan[2]"
          :xl="searchControl.thirdSoltSpan[3]"
        >
          <div class="soltWrap">
            <slot name="tool"></slot>
          </div>
        </el-col>
      </el-row>

      <el-table
        ref="table"
        :data="data"
        border
        highlight-current-row
        row-key="id"
        @cell-click="cellClick"
        @selection-change="getSelection($event)"
      >
        <!-- @row-dblclick="showEdit($event)" -->
        <el-table-column
          v-if="isSelectShow"
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in props"
          :key="index"
          v-bind="item"
          :width="item.width"
        >
          <template slot-scope="scope">
            <span v-if="item.type && item.type == 'content'">
              <span class="ellipsis">{{ scope.row.smsContent }}</span>
            </span>
            <span v-else-if="item.type && item.type == 'image'">
              <el-image
                class="tablePic"
                :src="`${url}/${scope.row[item.prop]}`"
                :preview-src-list="`${url}/${scope.row[item.prop]}`.split(' ')"
              ></el-image>
            </span>
            <span v-else-if="item.type && item.type == 'operationBtn'">
              <template v-for="(v, i) in item.formatter(scope.row)">
                <el-button
                  v-if="v.show"
                  :type="v.type"
                  @click="v.handler"
                  :key="i"
                  >{{ v.text }}</el-button
                >
              </template>
            </span>
            <span v-else-if="item.type && item.type == 'operationLink'">
              <template v-for="(v, i) in item.formatter(scope.row)">
                <el-link
                  :style="{
                    marginRight:
                      item.formatter(scope.row).length > 1 && i == 0
                        ? '20px'
                        : '',
                  }"
                  :icon="v.icon"
                  :key="i"
                  :type="v.type"
                  v-if="v.show"
                  @click="handlerType(v)"
                  >{{ v.text }}</el-link
                >
              </template>
            </span>
            <span v-else-if="item.type && item.type == 'btnLink'">
              <template v-for="(v, i) in item.formatter(scope.row)">
                <el-link
                  :style="{
                    marginRight:
                      item.formatter(scope.row).length > 1 && i == 0
                        ? '20px'
                        : '',
                  }"
                  :icon="v.icon"
                  :key="i"
                  :type="v.type"
                  v-if="v.show"
                  @click="v.handler"
                  >{{ v.text }}</el-link
                >
              </template>
            </span>
            <span v-else-if="item.type && item.type == 'colorText'">
              <span :style="{ color: item.formatter(scope.row).color }">{{
                item.formatter(scope.row).text
              }}</span>
            </span>
            <span v-else-if="item.type && item.type == 'switch'">
              <template>
                <el-switch
                  v-model="scope.row[item.prop]"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#ccc"
                  @change="item.formatter().handler(scope.row)"
                >
                <!-- row.formatter(row).handler(row) -->
                </el-switch>
              </template>
            </span>
            <span v-else>{{ handlerFormatter(item, scope.row) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        v-bind="pageInfo"
        :layout="layout"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
      <!--    模态框-->
      <el-dialog
        :title="formType === 'new' ? '新增-' + editTitle : '编辑-' + editTitle"
        center
        :visible.sync="dialogVisible"
        width="800px"
        :close-on-click-modal="false"
      >
        <el-form
          label-width="80px"
          ref="formRef"
          :inline="isInline"
          :model="form"
          class="inlineForm"
          :rules="rules"
        >
          <el-form-item
            v-for="(item, index) in formProps"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            :rules="item.rules"
            :class="item.isFull ? 'fullWidth' : ''"
          >
            <el-input
              :style="isInline ? { width: '270px' } : ''"
              v-if="item.type == 'input'"
              v-model="form[item.prop]"
              :placeholder="item.placeholder"
            ></el-input>

            <el-input
              v-if="item.type == 'textarea'"
              :style="isInline ? { width: '642px' } : ''"
              type="textarea"
              :rows="3"
              :placeholder="item.placeholder"
              v-model="form[item.prop]"
            >
            </el-input>

            <!-- 下拉框 -->
            <el-select
              filterable
              v-if="item.type == 'select'"
              v-model="form[item.prop]"
              :multiple="item.multiple"
              class="inlineFormSelect"
            >
              <el-option
                v-for="(items, indexs) in item.selectData"
                :key="indexs"
                :label="items.label"
                :value="items.value"
              ></el-option>
            </el-select>
            <!--层级 -->
            <el-cascader
              v-if="item.type == 'cascader'"
              class="inlineFormSelect"
              v-model="form[item.prop]"
              :options="item.selectData"
              :props="{ checkStrictly: true, emitPath: false }"
              clearable
            ></el-cascader>
            <!-- 日期 -->
            <el-date-picker
              :style="{ width: '270px' }"
              v-if="item.type == 'date'"
              v-model="form[item.prop]"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <!-- 单选 -->
            <el-radio-group
              v-if="item.type == 'radio'"
              v-model="form[item.prop]"
            >
              <el-radio
                v-for="(items, indexs) in item.selectData"
                :key="indexs"
                :label="items.value"
                >{{ items.lable }}</el-radio
              >
            </el-radio-group>
            <!-- 图片上传 -->
            <el-upload
              v-if="item.type == 'upload'"
              class="avatar-uploader"
              action="api/file/upload"
              :on-success="handleSuccess"
              :headers="headers"
              :show-file-list="false"
            >
              <img
                v-if="form[item.prop]"
                :src="`${url}/${form[item.prop]}`"
                class="avatar"
                @click="setPicField(item.prop)"
              />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                @click="setPicField(item.prop)"
              ></i>
            </el-upload>
            <!-- 富文本 -->
            <Editor
              v-if="item.type == 'editor'"
              :style="{ height: '100%' }"
              v-model="form[item.prop]"
              :propCon="form[item.prop]"
              @postContent="getContent"
            ></Editor>
          </el-form-item>

          <!-- <el-form-item class="footerBtn"> -->
          <div class="flex justify-center align-center">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button
              v-if="formType == 'new'"
              type="primary"
              @click="handelAdd('formRef')"
              >提交</el-button
            >
            <el-button
              v-if="formType == 'edit'"
              type="primary"
              @click="handelEdit('formRef')"
              >提交</el-button
            >
          </div>

          <!-- </el-form-item> -->
        </el-form>
      </el-dialog>
      <el-dialog :visible.sync="operationVisible">
        <el-table
          ref="table"
          :data="selecTionArr"
          border
          highlight-current-row
          row-key="id"
          @row-dblclick="showEdit($event)"
        >
          <el-table-column
            v-for="(item, index) in props"
            :key="index"
            v-bind="item"
            :width="item.width"
          >
            <template slot-scope="scope">
              <span v-if="item.type && item.type == 'image'">
                <el-image
                  class="tablePic"
                  :src="`${url}/${scope.row[item.prop]}`"
                  :preview-src-list="`${url}/${scope.row[item.prop]}`.split(' ')"
                ></el-image>
              </span>
              <span v-else-if="item.type && item.type == 'operation'">
                <template v-for="(v, i) in item.formatter(scope.row)">
                  <el-button
                    :type="v.type"
                    @click="v.handler"
                    :key="i"
                    :size="v.size"
                    >{{ v.text }}</el-button
                  >
                </template>
              </span>
              <span v-else>{{ handlerFormatter(item, scope.row) }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";

export default {
  name: "AppList",
  props: {
    remote: { type: Object, default: null },
    buttonShow: { type: Boolean, default: true },
    isSelectShow: { type: Boolean, default: true },
    showDialogContent: { type: Boolean, default: false },
    isInline: { type: Boolean, default: false },
    apiName: { type: String, default: "" },
    editTitle: { type: String, default: "" },
    labelWidth: { type: String, default: "100px" },
    searchQuery: { type: Object, default: null },
    rules: { type: Object, default: null }, //applist接收表单验证
    searchControl: {
      type: Object,
      default: () => {
        return {
          firstSoltSpan: [10, 9, 8, 5],
          secondSoltSpan: [0, 0, 0, 0],
          thirdSoltSpan: [14, 15, 16, 19],
        };
      },
    },
    btnFlag: {
      type: Object,
      default: () => {
        return {
          addShow: true,
          editShow: true,
          deleteShow: true,
        };
      },
    },
    addBtnText: { type: String, default: "新增" },
    editBtnText: { type: String, default: "编辑" },
    deleteBtnText: { type: String, default: "删除" },

    props: {
      type: Array,
      default: () => {
        return [];
      },
    },
    formProps: {
      type: Array,
      default: () => {
        return [];
      },
    },
    rowClickable: Boolean,
    formatterColor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: 1,
      dialogVisiblePic: false,
      operationVisible: false,
      showContentVisible: false,
      pageInfo: {
        currentPage: 1,
        pageCount: 1,
        pageSize: 10,
        total: 0,
      },
      selecTionArr: [],
      formType: "new",
      form: {},
      dialogVisible: false,
      data: [],
      nowFieldName: "",
      layout: "sizes, prev, pager, next, jumper",
      headers: {
        token: getToken(),
      },
      url:process.env.VUE_APP_HOST,
    };
  },
  async created() {
    await this.getItems();
  },
  watch: {
    searchQuery: {
      handler(val) {
        this.pageInfo = Object.assign(this.pageInfo, val);
        console.log("wdwedasdsad-->", this.pageInfo);
        this.getItems();
      },
      immediate: true,
      deep: true, //开启深度监听
    },
  },
  methods: {
    // 手动开关 ——>是否推荐
    async handleSwitch(e) {
      // await this.remote.xxx({
      //   apiName:"",

      // })
      console.log(e);
    },
    test(row) {
      row.formatter(row).handler(row)
    },
    setPicField(str) {
      this.nowFieldName = str;
    },
    handleSuccess(file) {
      this.$set(this.form, this.nowFieldName, file.data.path);
    },
    async getItems() {
      this.pageInfo.apiName = this.apiName;
      console.log(this.pageInfo);
      await this.remote.list(this.pageInfo).then((res) => {
        this.data = res.data.data;
        this.pageInfo.total = parseInt(res.data.total);
        console.log("数据列表", this.data);
      });
    },
    getContent(val) {
      this.form.content = val;
    },
    showAdd() {
      this.formType = "new";
      this.form = {};
      this.dialogVisible = true;
    },
    // 未知操作
    showOperation() {
      this.operationVisible = true;
    },
    async handelAdd(formName) {
      this.$refs[formName].validate((right) => {
        if (right) {
          this.form.apiName = this.apiName;
          this.remote.create(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({ message: res.message, type: "success" });
              this.dialogVisible = false;
              this.getItems();
            } else {
              this.$message(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    showEdit(e) {
      // if (this.isSelectShow) {
      this.formType = "edit";
      this.form = e;
      this.dialogVisible = true;
      // }
    },

    async handelEdit(formName) {
      this.$refs[formName].validate((right) => {
        if (right) {
          this.form.apiName = this.apiName;
          this.remote.update(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({ message: res.message, type: "success" });
              this.dialogVisible = false;
              this.getItems();
            } else {
              this.$message(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    //详情
    async jumpToDetail() {
      console.log("跳转的ID", this.form.id);

      // this.form.apiName = this.apiName;
      // await this.remote.detail(this.form.id).then((res) => {
      //   if (res.code == 200) {
      //     this.$message({ message: res.message, type: "success" });
      //   } else {
      //     this.$message(res.message);
      //   }
      //   this.dialogVisible = false;
      //   this.getItems();
      // });
    },

    async handelDel() {
      let ids = [];

      this.selecTionArr.forEach((item) => {
        ids.push(item.id);
      });
      const query = {
        ids: ids,
        apiName: this.apiName,
      };
      await this.remote.remove(query).then((res) => {
        if (res.code == 200) {
          this.$message({ message: res.message, type: "success" });
        } else {
          this.$message(res.message);
        }

        this.getItems();
      });
    },
    async handelRevoke() {
      let ids = [];

      this.selecTionArr.forEach((item) => {
        ids.push(item.id);
      });
      const query = {
        ids: ids,
        apiName: this.apiName,
      };
      await this.remote.revoke(query).then((res) => {
        if (res.code == 200) {
          this.$message({ message: res.message, type: "success" });
        } else {
          this.$message(res.message);
        }

        this.getItems();
      });
    },
    getSelection(e) {
      console.log(e);
      this.selecTionArr = e;
    },
    async onSizeChange(e) {
      this.pageInfo.pageSize = e;
      this.getItems();
    },
    async onPageChange(e) {
      this.pageInfo.currentPage = e;
      this.getItems();
    },
    handlerFormatter(item, row) {
      if (item.formatter) {
        return item.formatter(row);
      } else {
        return row[item.prop];
      }
    },
    handlerFormatterColor(item, row) {
      console.log("sdfsdfdsf---<", item.formatter(row));
      console.log("row12312312312---<", row);
    },
    handlerType(row) {
      if (row.handlerType == "edit") {
        this.showEdit(row.data);
      } else if (row.handlerType == "delete") {
        this.selecTionArr = [row.data];
        this.handelDel();
      } else if (row.handlerType == "revoke") {
        this.selecTionArr = [row.data];
        this.handelRevoke();
      }
      // else if (row.handlerType == "detail") {
      //   this.selecTionArr = [row.data];
      //   this.handelRevoke();
      // }
      else {
        row.handler();
      }
    },
    cellClick(row, column) {
      this.$emit("cellClick", { row, column });
    },
  },
};
</script>

<style lang="scss" scoped>
.fullWidth {
  width: 100% !important;
  overflow: hidden;
}
.mb20 {
  margin-bottom: 20px;
}
.autoWidth {
  width: calc(100% - 80px);
}
.el-form-item.is-required .el-form-item__label:before {
  content: none !important;
}
.avatar-uploader {
  border: 1px dashed #d9d9d9;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.inlineForm .inlineFormSelect {
  width: 270px;
}

.inlineForm .inlineFormInput {
  width: 270px;
}
.quill-editor {
  margin-bottom: 20px;
}
.el-form--inline .el-form-item {
  width: 48%;
}
.upload_info {
  position: absolute;
  bottom: 2px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: rgba($color: #000, $alpha: 0.2);
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 270px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 260px;
  height: 178px;
  display: block;
}

.toolWrap {
  padding: 20px 0;
}

.soltWrap {
  margin-left: 10px;
}
.footerBtn {
  text-align: center;
}
.list {
  display: flex;
  flex-direction: column;

  .el-table {
    overflow: auto;
  }

  .el-pagination {
    padding: 0;
    padding-top: 24px;
  }

  .indent .cell {
    margin-left: 23px;
  }
}
.tablePic {
  width: 120px;
  height: 80px;
  border-radius: 5px;
  /deep/ .el-image-viewer__close{
    color: #fff;
    opacity: 1;
  }
}
.green {
  color: green;
}
.red {
  color: red;
}
.editor /deep/ .ql-editor.ql-blank {
  min-height: 300px;
}
</style>
