import axios from "axios"
import { Message } from "element-ui"
import store from "@/store"
import { getToken, removeToken } from "@/utils/auth"
import NProgress from "nprogress" // progress bar
import "nprogress/nprogress.css" // progress bar style
import router from "@/router"

NProgress.configure({ showSpinner: false })

const service = axios.create({
	baseURL: "/api",
	timeout: 5000,
})

service.interceptors.request.use(
	(config) => {
		NProgress.start()
		if (store.getters.token) {
			config.headers.common["token"] = getToken()
		}
		return config
	},
	(error) => {
		NProgress.done()
		return Promise.reject(error)
	}
)

service.interceptors.response.use(
	(response) => {
		NProgress.done()
		if (response.config.raw) {
			return response
		} else if (response.data.code == 200) {
			return response.data
		} else {
			Message({
				message: response.data.message,
				type: "error",
			})
			return false
		}
	},
	(error) => {
		Message({
			message: error.response.data.message,
			type: "error",
		})
		if (error.response.status == 401) {
			removeToken()
			NProgress.done()
			return router.replace({ path: "/login" })
		} else {
			NProgress.done()
			return Promise.reject(error)
		}
	}
)

export default service
