import Vue from "vue"
import "normalize.css/normalize.css" // A modern alternative to CSS resets
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import "@/styles/index.scss" // global css
import App from "./App"
import store from "./store"
import router from "./router"
import "@/icons" // icon
import "@/permission" // permission control
import "@/components"
import VueQuillEditor from "vue-quill-editor"
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"
import test from "@/utils/test"
import * as utils from "./utils/index"
// import VueSocketIO from "vue-socket.io"

Vue.use(VueQuillEditor)
Vue.use(ElementUI)

// Vue.use(
// 	new VueSocketIO({
// 		debug: true,
// 		connection: process.env.VUE_APP_HOST_IO,
// 		options: {
// 			query: "loginUserNum=DP79",
// 		},
// 	})
// )

Vue.config.productionTip = false
Vue.prototype.$test = test
Vue.prototype.$utils = utils

new Vue({
	el: "#app",
	router,
	store,
	render: (h) => h(App),
})
