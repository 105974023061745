<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style lang="scss">
/* #app .sidebar-container {
  background: url("assets/images/menuBg.png") no-repeat;
} */
* {
  padding: 0;
  margin: 0;
}
p {
  padding: 0;
  margin: 0;
  line-height: 30px;
}
@for $i from 1 through 200 {
  .m-#{$i} {
    margin: $i + px;
  }
  .m-t-#{$i} {
    margin-top: $i + px;
  }
  .m-b-#{$i} {
    margin-bottom: $i + px;
  }
  .m-l-#{$i} {
    margin-left: $i + px;
  }
  .m-r-#{$i} {
    margin-right: $i + px;
  }
  .m-l-t-#{$i} {
    margin-left: $i + px;
    margin-top: $i + px;
  }
  .m-l-r-#{$i} {
    margin-left: $i + px;
    margin-right: $i + px;
  }
  .m-t-b-#{$i} {
    margin-top: $i + px;
    margin-bottom: $i + px;
  }

  .p-#{$i} {
    padding: $i + px;
  }
  .p-t-#{$i} {
    padding-top: $i + px;
  }
  .p-b-#{$i} {
    padding-bottom: $i + px;
  }
  .p-l-#{$i} {
    padding-left: $i + px;
  }
  .p-r-#{$i} {
    padding-right: $i + px;
  }
  .p-t-b-#{$i} {
    padding-top: $i + px;
    padding-bottom: $i + px;
  }
  .p-l-r-#{$i} {
    padding-left: $i + px;
    padding-right: $i + px;
  }

  .font-#{$i} {
    font-size: $i + px;
  }
  .radius-#{$i} {
    border-radius: $i + px;
    -webkit-border-radius: $i + px;
    -moz-border-radius: $i + px;
    -ms-border-radius: $i + px;
    -o-border-radius: $i + px;
  }
}
.commonTop {
  margin-bottom: 10px;
}
.pagination {
  margin-top: 15px;
}
.ml20 {
  margin-left: 20px;
}
.mr20 {
  margin-right: 20px;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.el-input-group__append,
.el-input-group__prepend {
  padding: 0 10px !important;
}
.info-form label {
  font-weight: normal;
}
.el-form-item__label {
  text-align: justify;
  height: 50px;
}
.el-dialog__header {
  background: url("assets/images/formHead.png") no-repeat;
  background-size: 100% 100%;
}
.el-dialog__title {
  color: #fff;
}
.searchInput {
  width: 250px;
  margin-right: 10px;
  font-size: 14px;
  margin-bottom: 10px;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.align-center {
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}

.search {
  display: flex;
  flex-wrap: wrap;
}
.lableName {
  line-height: 40px;
  background: #f5f7fa;
  color: #909399;
  height: 40px;
  border: 1px solid #dcdfe6;
  padding: 0 8px;
  border-right: none;
  left: 2px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  font-size: 14px;
  display: inline-block;
  vertical-align: top;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ellipsis--l2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ellipsis--l3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
